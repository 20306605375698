export const Flags = (props) => {
  return (
    <div id="Flags" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Estamos presentes:</h2>
          <p>
            En todos los países dónde hay familias homeschoolers de habla
            hispana.
          </p>
        </div>
        <div className="row primerFila">
          <div
            className="col-md-1 col-sm-2 col-xs-6 flagContainer"
            style={{ width: "auto" }}
          >
            <i className="round-flag-icon round-flag-ar flags"></i>
            <div>Argentina</div>
          </div>
          <div
            className="col-md-1 col-sm-3 col-xs-6 flagContainer"
            style={{ width: "auto" }}
          >
            <i className="round-flag-icon round-flag-mx flags"></i>
            <div>México</div>
          </div>
          <div
            className="col-md-1 col-sm-3 col-xs-6 flagContainer"
            style={{ width: "auto" }}
          >
            <i className="round-flag-icon round-flag-ec flags"></i>
            <div>Ecuador</div>
          </div>
          <div
            className="col-md-1 col-sm-3 col-xs-6 flagContainer"
            style={{ width: "auto" }}
          >
            <i className="round-flag-icon round-flag-pa flags"></i>
            <div>Panamá</div>
          </div>
          <div
            className="col-md-1 col-sm-3 col-xs-6 flagContainer"
            style={{ width: "auto" }}
          >
            <i className="round-flag-icon round-flag-id flags"></i>
            <div>Indonesia</div>
          </div>
          <div
            className="col-md-1 col-sm-3 col-xs-6 flagContainer"
            style={{ width: "auto" }}
          >
            <i className="round-flag-icon round-flag-co flags"></i>
            <div>Colombia</div>
          </div>
          <div
            className="col-md-1 col-sm-3 col-xs-6 flagContainer"
            style={{ width: "auto" }}
          >
            <i className="round-flag-icon round-flag-br flags"></i>
            <div>Brasil</div>
          </div>
          <div
            className="col-md-1 col-sm-3 col-xs-6 flagContainer"
            style={{ width: "auto" }}
          >
            <i className="round-flag-icon round-flag-do flags"></i>
            <div>
              República<br></br>Dominicana
            </div>
          </div>
        </div>
        <div className="row segundaFila">
          <div
            className="col-md-1 col-sm-3 col-xs-6 flagContainer"
            style={{ width: "auto" }}
          >
            <i className="round-flag-icon round-flag-ni flags"></i>
            <div>Nicaragua</div>
          </div>
          <div
            className="col-md-1 col-sm-3 col-xs-6 flagContainer"
            style={{ width: "auto" }}
          >
            <i className="round-flag-icon round-flag-my flags"></i>
            <div>Malasia</div>
          </div>
          <div
            className="col-md-1 col-sm-3 col-xs-6 flagContainer"
            style={{ width: "auto" }}
          >
            <i className="round-flag-icon round-flag-pr flags"></i>
            <div>Puerto Rico</div>
          </div>
          <div
            className="col-md-1 col-sm-3 col-xs-6 flagContainer"
            style={{ width: "auto" }}
          >
            <i className="round-flag-icon round-flag-uy flags"></i>
            <div>Uruguay</div>
          </div>
          <div
            className="col-md-1 col-sm-3 col-xs-6 flagContainer"
            style={{ width: "auto" }}
          >
            <i className="round-flag-icon round-flag-gw flags"></i>
            <div>Guinea-Bisáu</div>
          </div>
          <div
            className="col-md-1 col-sm-3 col-xs-6 flagContainer"
            style={{ width: "auto" }}
          >
            <i className="round-flag-icon round-flag-ma flags"></i>
            <div>Marruecos</div>
          </div>
        </div>
        <div className="row tercerFila">
          <div
            className="col-md-1 col-sm-3 col-xs-6 flagContainer"
            style={{ width: "auto" }}
          >
            <i className="round-flag-icon round-flag-py flags"></i>
            <div>Paraguay</div>
          </div>
          <div
            className="col-md-1 col-sm-3 col-xs-6 flagContainer"
            style={{ width: "auto" }}
          >
            <i className="round-flag-icon round-flag-es flags"></i>
            <div>España</div>
          </div>
          <div
            className="col-md-1 col-sm-3 col-xs-6 flagContainer"
            style={{ width: "auto" }}
          >
            <i className="round-flag-icon round-flag-il flags"></i>
            <div>Israel</div>
          </div>
          <div
            className="col-md-1 col-sm-3 col-xs-6 flagContainer"
            style={{ width: "auto" }}
          >
            <i className="round-flag-icon round-flag-pe flags"></i>
            <div>Perú</div>
          </div>
          <div
            className="col-md-1 col-sm-3 col-xs-6 flagContainer"
            style={{ width: "auto" }}
          >
            <i className="round-flag-icon round-flag-ht flags"></i>
            <div>Haiti</div>
          </div>
          <div
            className="col-md-1 col-sm-3 col-xs-6 flagContainer"
            style={{ width: "auto" }}
          >
            <i className="round-flag-icon round-flag-it flags"></i>
            <div>Italia</div>
          </div>
          <div
            className="col-md-1 col-sm-3 col-xs-6 flagContainer"
            style={{ width: "auto" }}
          >
            <i className="round-flag-icon round-flag-gb flags"></i>
            <div>Reino Unido</div>
          </div>
          <div
            className="col-md-1 col-sm-3 col-xs-6 flagContainer"
            style={{ width: "auto" }}
          >
            <i className="round-flag-icon round-flag-hn flags"></i>
            <div>Honduras</div>
          </div>
        </div>
      </div>
    </div>
  );
};
